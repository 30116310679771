'use client';

import React from 'react';
import dynamic from 'next/dynamic';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const App = dynamic(() => import('../../App'), { ssr: false });

export const ClientOnly: React.FC = () => {
  return <App />;
};
